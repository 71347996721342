@mixin transition-all()
{
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}

// Navbar Related
header, nav {
  z-index: 10;
}

nav.scrolled {
  @apply shadow-2xl;
  border-bottom: 0px;
}

ul.list-text li{
  z-index: 0;
}
// .content{
//   padding-left: 2em;
//   padding-right: 2em;
// }

@media (max-width: 640px) {
  .content {
    padding-top: 4em;
  }
}

@media (min-width: 640px) and (max-width: 800px){
  #menu{
    font-size: 0.9rem !important;
  }
}

@media (min-width: 640px) {
  .pt-scroll {
    padding-top: 4em;
  }

  .nav-sticky {
    position: fixed!important;
    min-width: 100%;
    top: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    transition: all .25s ease-in;
    z-index: 1;
  }
}

/* HAMBURGER MENU */

.hamburger {
  cursor: pointer;
  width: 48px;
  height: 48px;
  transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  @apply bg-blue-900 #{!important};
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger:hover [class*="-bun"] {
  background: #333;
}

.hamburger__top-bun {
  transform: translateY(-5px);
}

.hamburger__bottom-bun {
  transform: translateY(3px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(-1px);
}

.open .hamburger__top-bun {
  transform:
    rotate(45deg)
    translateY(0px);
}

.open .hamburger__bottom-bun {
  transform:
    rotate(-45deg)
    translateY(0px);
}

// Profile Picture
#profpict {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.img__wrap {
  position: relative;
  width: 15em;
  height: 15em;
}

.img__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  opacity: 0;

  /* transition effect. not necessary */
  transition: .5s ease;
}

.img__wrap:hover .img__description {
  opacity: 1;
}

.text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.dark h2,
.dark h3,
.dark h4,
.dark h5 {
  @apply text-blue-200 #{!important};
}

// Skills
.highlight{
  font-weight: bold;
  @apply text-blue-900 #{!important};
}

.dark .highlight{
  font-weight: bold;
  @apply text-blue-500 #{!important};
}

// Locations
#location-table{
  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 640px) {
    width: 100%;
  }
  vertical-align: middle;
  // .table-loc{
  //   min-width: 160px;
  // }
}

.location-item {
  vertical-align: middle;
  text-align:center;
}

.location-img {
  width: 7em;
}

// Projects related
.add-r-margin{
  margin-right: 1em;
}

.add-l-margin{
  margin-left: 1em;
}

// headers
h3 {
  margin-top: 1rem !important;
}

h4 {
  margin-top: 0rem !important;
}

// code
.dark .prose pre {
  // @apply bg-blue-900;
  @apply bg-gray-700;
  @apply text-blue-200;
  // color:#1f2937;
}

.dark .prose code {
  @apply text-blue-200;
}

// hyperlinks
.dark a.hyperlink {
  @apply text-blue-200 #{!important};
}

a.hyperlink:hover{
  @apply text-blue-600 #{!important};
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
  vertical-align: middle;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  left: 0.2rem;
  bottom: 0.1rem;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  @apply bg-blue-900;
}

input:checked + .slider:before {
  -webkit-transform: translateX(0.8rem);
  -ms-transform: translateX(0.8rem);
  transform: translateX(0.8rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1rem;
}

.slider.round:before {
  border-radius: 50%;
}

// Paragraph
p {
  text-align: justify;
}
