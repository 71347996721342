// Barlow
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/Barlow-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/Barlow-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/Barlow-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/Barlow-BoldItalic.ttf') format('truetype');
}

// Fira Sans
@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/FiraSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'FiraSans';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/FiraSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/FiraSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'FiraSans';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/FiraSans-BoldItalic.ttf') format('truetype');
}
// Nunito
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/Nunito-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/Nunito-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/Nunito-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/Nunito-BoldItalic.ttf') format('truetype');
}

// Nunito Sans
@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/NunitoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'NunitoSans';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/NunitoSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/NunitoSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'NunitoSans';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
}
// Open Sans
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/OpenSans-BoldItalic.ttf') format('truetype');
}
// Source Sans Pro
@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/SourceSansPro-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/SourceSansPro-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/SourceSansPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/SourceSansPro-BoldItalic.ttf') format('truetype');
}
// Ubuntu
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/Ubuntu-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('../webfonts/Ubuntu-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/Ubuntu-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../webfonts/Ubuntu-BoldItalic.ttf') format('truetype');
}
